import { getCode } from "./getValidProjectLocale";

function getTopLevelDomain(code: string) {
  switch (code) {
    case "indicotravels":
    case "indico-travels":
      return "org";
    case "chinaventura":
    case "polartours":
    case "fairtrips":
    case "grekaventura":
      return "com";
    default:
      return "info";
  }
}

export const SMALLER_LOGOS = ["indicotravels", "indico-travels", "sapio"];

function buildUrl(
  brandName: string,
  fileName: string = "logo",
  locale: string = "de_DE"
) {
  const code = getCode(brandName);
  return `https://cdn.${code}.${getTopLevelDomain(
    code
  )}/uploads/i18n/${locale}/logos/${fileName}.svg`;
}

function getLogoUrl(brandName: string, mobile: boolean) {
  const code = getCode(brandName);
  switch (code) {
    case "viventura":
    case "japaventura":
    case "grekaventura":
      return mobile ? buildUrl(code, "logo_color") : buildUrl(code);
    case "puraventura":
      return mobile ? buildUrl(code) : buildUrl(code, "logo_white");
    case "persiaventura":
      return mobile
        ? buildUrl(code)
        : buildUrl(code, "logo_all_white", "fr_FR");
    case "galapatours":
      return "https://storage.googleapis.com/media_ventura_travel/media-admin/e5339d85-1f8e-41a4-ba7a-b700f2c47c61-galapatours.svg";
    case "asiaventura":
      return "https://s3.eu-central-1.amazonaws.com/asiaventura.images/uploads/backend/signin_logo.png";
    case "indicotravels":
    case "indico-travels":
      return "https://storage.googleapis.com/media_ventura_travel/indico-travels-logo.png";
    case "polartours":
      return "https://storage.googleapis.com/media_ventura_travel/e5932943-35ed-4b1f-9696-3a992ad2977f-logo-1-.svg";
    case "fairtrips":
      return "https://storage.googleapis.com/media_ventura_travel/5948e5e4-90f5-49d1-9dc2-04cc054b3c43-logo-fai.svg";
    case "allura-blue":
    case "allurablue":
      return mobile
        ? "https://storage.googleapis.com/media_ventura_travel/allura_blue_logo.svg?v=12j2"
        : "https://storage.googleapis.com/media_ventura_travel/allura_blue_white_logo.svg?v=sa1";
    case "africaventura":
      return "https://media.venturatravel.org/unsafe/0x125/center/middle/smart/fa593d7c-92b7-455b-a282-f41964dd9107-africaventura_color-4x.png";
    case "china-tours":
    case "chinatours":
      return "https://media.venturatravel.org/unsafe/0x125/center/middle/smart/910326bd-e9a6-46ba-836f-829e59e0d707-china-tours-logo-cmyk.png";
    case "sapio":
      return "https://storage.googleapis.com/media_ventura_travel/304afbca-d351-4ca1-9d92-b4737134dd78-sapio_logo_drot.svg";
    case "epictrips":
      return "https://storage.googleapis.com/media_ventura_travel/b1223c3b-ac5f-4e1a-a0dc-38728eafe489-epic-trips-logo-1.svg";
    case "feast":
      return "https://storage.googleapis.com/media_ventura_travel/f38707f5-528c-4f62-954b-b599233edcb8-feast_logo_cmyk_l_f-c03-c_noword.svg";
    case "diveindonesia":
      return "https://storage.googleapis.com/media_ventura_travel/bd662c14-5eb3-4ebc-ae58-88b87d953752-color-logo-with-background.svg";
    case "gustoventura":
      return "https://storage.googleapis.com/media_ventura_travel/923c9487-7602-4884-bab1-c01efb7e2485-logo1-gustoventura.svg";
    default:
      return buildUrl(code);
  }
}

export default getLogoUrl;
