import { getCode } from "./getValidProjectLocale";

function getBrandColor(brandName: string = "") {
  const code = getCode(brandName);
  switch (code) {
    case "viventura":
      return "#006228";
    case "puraventura":
      return "#005583";
    case "japaventura":
      return "#600000";
    case "persiaventura":
      return "#0569a5";
    case "chinaventura":
      return "#f5b900";
    case "grekaventura":
      return "linear-gradient(271deg, rgb(33, 104, 129) 0%, rgb(23, 86, 153) 31%, rgb(40, 43, 127) 100%)";
    case "fairtrips":
      return "white";
    case "asiaventura":
      return "#2f406b";
    case "polartours":
      return "#d2ecff";
    case "allura-blue":
    case "allurablue":
      return "#353b50";
    case "sapio":
      return "#C9C29C";
    case "epictrips":
      return "#0d2f5d";
    case "feast":
      return "#f2af88";
    case "diveindonesia":
      return "#3DA5AA";
    case "gustoventura":
      return "#f2af88";
    default:
      return "white";
  }
}

export default getBrandColor;
