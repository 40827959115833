function getAvailableBrands(): Array<string> {
  return [
    "africaventura",
    "allura-blue",
    "allurablue",
    "arabiaventura",
    "asiaventura",
    "china-tours",
    "chinatours",
    "chinaventura",
    "fairtrips",
    "galapatours",
    "grekaventura",
    "indico-travels",
    "indicotravels",
    "japaventura",
    "miaventura",
    "persiaventura",
    "polartours",
    "puraventura",
    "sapio",
    "viventura",
    "epictrips",
    "feast",
    "diveindonesia",
    "gustoventura",
  ];
}

// Please update this array when you want enable a new platform brand
function getPlatformBrands(): Array<string> {
  return ["galapatours"];
}

function getAvailableLocales() {
  return [
    "fr_FR",
    "fr",
    "de_DE",
    "de",
    "en_US",
    "en_GB",
    "en",
    "es",
    "es_ES",
    "es_CO",
    "nl",
    "nl_NL",
  ];
}

function getCode(brand: string = "") {
  return brand?.toLowerCase().trim()?.replace(" ", "-");
}

function isBrandAvailable(brand: string = "") {
  return getAvailableBrands().includes(getCode(brand));
}

function isLocaleAvailable(locale: string = "") {
  return getAvailableLocales().includes(locale);
}

function isPlatform(brand: string = "") {
  return getPlatformBrands().includes(getCode(brand));
}

export { isBrandAvailable, isLocaleAvailable, isPlatform, getCode };
